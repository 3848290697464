/* eslint-disable */
import React, {useContext} from "react"
import styled from '@emotion/styled'
import { Form } from '@rocketseat/unform';
import PropTypes from "prop-types"
import * as Yup from 'yup';
import { Context as EntryContext } from '../context/EntryContext';
// import { intlShape, injectIntl, FormattedMessage} from 'react-intl';


import Section from '../components/blocks/section'
import StyledInput from '../components/fields/input'
import Checkbox from '../components/fields/checkbox'
import Error from '../components/fields/error'
import Button from '../components/fields/button'

import WinImg from '../images/win@2x.png'


const StyledForm = styled(Form)`
  width:58%;
  padding: 0 8%;
  transition: 0.5s;
  opacity: ${props => props.modal ? '0':'1'};
  pointer-events: ${props => props.modal ? 'none':'all'};
  transform: ${props => props.modal ? 'scale3d(1.2,1.2,1.2)':'scale3d(1,1,1)'};
  position: absolute;
  top: 16%;
  z-index: 3;
  @media(max-width: 1024px){
    width: 100%;
    padding: 0 25px;
    position: ${props => props.modal ? 'absolute':'relative'};
    bottom: auto;
    top: 140px;
    z-index: 9;
  }
`
const BallContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  p {
    color: white;
    font-weight: bold;
    text-align:center;
    line-height: 25px;
    width: 370px;
    margin: -30px calc(50% - 185px) 15px;
  }
  @media(max-width: 1024px){
    p {
      width: 90%;
      margin: 0px 5% 15px;
    }
  }
`


const FlexOrder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    span, label {
        order: 1;
        margin: 0 5px;
    }
    input {
        order: 2;
        width: 100%;
    }
    span {
      color: red;
    }
`
const LegalTerms = styled.div`
    font-size: 11pt;
    color: white;
    font-family: 'MuseoSans-500',sans-serif;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    a {
        color: #A2A2A2;
    }
    label {
        padding-left: 10px;
        width: calc(100% - 60px);
    }
    span {
      color: red;
    }
`

const schema = Yup.object().shape({
  email: Yup.string()
    .email('The email is not valid')
    .required('Required'),
  legalTerms: Yup.bool()
    .oneOf([true], 'You must accept the rules')
});

const HomeForm = () => {
    const { state, startEntryProcess } = useContext(EntryContext)
    return(
      <>
          <StyledForm
              schema={schema}
              onSubmit={(data) => startEntryProcess(data)}
              modal={state.modal}
              >
              <BallContainer>
                <img src={WinImg} />
                <p>Enter to WIN* a $300 Patriots ProShop Gift Card or 1 of 120 $100 Patriots ProShop Gift Cards.</p>
              </BallContainer>
              <Section
                width="300px"
                margin="0 auto"
                padding="0"
                display="block">
                <FlexOrder>
                    <StyledInput
                        round={false}
                        label='Email Address'
                        name="email"
                        />
                </FlexOrder>
                <LegalTerms>
                    <Checkbox
                        name="legalTerms"
                        />
                      <label htmlFor="legalTerms">I have read and agree to the <a href='/patriots_2020.pdf' class="white-link" target='_blank'>Official Rules</a></label>
                </LegalTerms>
                { state.customer_alreadyEntered ?
                    <Error>
                        You already entered the contest today. Come back tomorrow for another chance to win!
                    </Error>
                    :
                    null
                 }
                <Button
                  fullWidth
                  type="submit">
                    Enter Now
                </Button>

              </Section>
          </StyledForm>

      </>
    )
}

export default HomeForm
