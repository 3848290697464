/* eslint-disable */
import React, {useContext, useState} from "react"
import styled from '@emotion/styled'
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { Context as EntryContext } from '../../context/EntryContext';
import ReCAPTCHA from "react-google-recaptcha";
// import { intlShape, injectIntl, FormattedMessage} from 'react-intl';

import Text from '../../atoms/text'
import StyledInput from '../../components/fields/input'
import Checkbox from '../../components/fields/checkbox'
import Button from '../../components/fields/button'
import Section from '../../components/blocks/section'
import Column from '../../components/blocks/column'

import Icon1 from '../../images/icon1.svg'
import Icon2 from '../../images/icon2.svg'
import Icon3 from '../../images/icon3.svg'
import ModalImage from '../../images/modalImage@2x.jpg'


const ModalImg = styled.img`

`

const StyledEntryForm = styled(Form)`
  padding: 0 25px 50px;
    label {
        color: #5F5F5F;
    }
    span {
        font-size: 11pt;
        line-height: 13pt;
        color: red;
        display: block;
        margin-left: 11px;
    }
    @media(max-width: 961px){
        display: ${props => !props.modal ? 'none':'block'};
        position: relative;
        left: 0%;
        width: 94%;
        padding: 4%;
        span {
            font-size: 11pt;
            line-height: 17pt;
        }
    }
`

const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
const postalCodeRegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

const FlexOrder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    span, label {
        order: 1;
        display: inline-block !important;
    }
    input {
        order: 2;
        width: 100%;
    }
`
const LegalTerms = styled.div`
    font-size: 11pt;
    color: #A2A2A2;
    font-family: 'Montserrat';
    font-weight: 300;
    margin-top: 10px;
    display: flex;
    flex-wrap:wrap;
    input, label {
      order: 2
    }
    label {
        padding-left: 10px;
        max-width: 90%;
    }
    span {
      width: 100%;
      order: 1,
      padding: 0;
      margin: 0 0 10px;
    }
`

const ColorList = styled.ul`
    margin: 0 0 25px;
`
const Items = styled.li`
    color: #006737;
    list-style-type: none;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center left;
    margin: 10px 0;
    padding: 0 0 0 35px;
    font-size: 15pt;

`
const Item1 = styled(Items)`
    background-image: url(${Icon1});
`
const Item2 = styled(Items)`
    background-image: url(${Icon2});
`
const Item3 = styled(Items)`
    background-image: url(${Icon3});
`

const ResponsiveSpacer = styled.div`
    @media(max-width: 961px){
        display: block;
        width: 100%;
        height: 50px;
    }
`

const callback = (data,setState) => {
    if(data){
      setState(true)
      console.log(data)
    }
}

const Step3 = (props) => {
    const { state, nextStep } = useContext(EntryContext)
    const [captcha, setCatpcha] = useState(state.recaptcha)
    const [error, setError] = useState(false)
    let initialData = {
        newsletter: state.customer_newsletter,
    };

    return(
      <>
          <StyledEntryForm
              onSubmit={(data) => {nextStep(data,state.modalStep,state.uuid)}}
              modal={state.modal}
              initialData={initialData}
              >
              <Section
                  fullWidth
                  padding="0"
                  margin="0">
                  <Column
                      width="40%"
                      padding="0% 10px 0 3%"
                      mobileOrder="2"
                      >
                      <span id="left-text">
                        <Text
                          margin="0px 0 40px 0"
                          fontSize="20pt"
                          lineHeight="30pt"
                          padding="40px 15% 0 0">
                            Last step before you’re entered into the sweepstakes.
                        </Text>
                      </span>
                      <LegalTerms>
                          <Checkbox
                              name="newsletter"
                              />
                            <label>Yes! I would like to subscribe to Scotties<sup>®</sup> emails to be among the first to know about savings, sweepstakes, product news and much more.</label>
                      </LegalTerms>
                      { error ?
                        <Error>
                          Please click the reCaptcha
                        </Error>
                        :null
                      }
                      <ReCAPTCHA
                        style={{marginTop:10}}
                        sitekey="6LeW9GkUAAAAAD5t1bNyz9URG4e3j8K5WWBbXIGE"
                        onChange={(data) => callback(data,setCatpcha)}
                      />
                      <Button
                        disabled={!captcha}
                        onClick={() => {
                          if(captcha === null){
                            setError(true)
                          } else {
                            setError(true)
                          }
                         }}
                         type="submit">
                         Submit
                       </Button>
                  </Column>
                  <Column
                      width="60%"
                      padding="0px 10px 0"
                      mobileOrder="2">
                      <ModalImg src={ModalImage} />
                  </Column>


              </Section>
          </StyledEntryForm>
      </>
    )
}

export default Step3
