/* eslint-disable */
import React, { useContext, useEffect } from "react"
import styled from '@emotion/styled'
import { Context as EntryContext } from '../context/EntryContext';
import { Link } from 'gatsby'

import Button from '../components/fields/button'

import Back from '../images/star_background@2x.jpg'
import StartBurst1 from '../images/LastBurst@2x.png'
import StartBurst2 from '../images/LastBurst2@2x.png'
import StartBurst3 from '../images/LastBurst3@2x.png'
import Thanks from '../images/thank@2x.png'
import WhiteRound from '../images/white_round@2x.png'
import BlueRound from '../images/blue_round@2x.png'

import Boxes from '../images/boxes_final@2x.png'
import Proshop from '../images/proshop@2x.png'

import StartBurstMobile1 from '../images/StartBurst_mobile@2x.png'
import StartBurstMobile2 from '../images/StartBurst2_mobile@2x.png'
import DownArrow1 from '../images/downarrow.svg'
import DownArrow2 from '../images/downarrow2.svg'

import StartBurstMobile3 from '../images/StartBurst3_mobile@2x.png'

import '../css/patriots2020.css'

const StarBackground = styled.div`
  width: 100%;
  height: 90vh;
  background-image: url(${Back});
  background-size: cover;
  position: relative;
  z-index: 0;
  overflow: hidden !important;
  @media(max-width: 1024px){
    height: auto;
  }
`

const AnimatedStar = styled.div`
  width: 100%;
  height: 90vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background-image: url(${StartBurst1});
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  z-index:0;
  opacity: 0;
  transform: rotate(0deg);
  pointer-events: none;
  transition: 0.5s;
  opacity: 0;
  animation: ${props => props.active ? 'none':'fade2 4s linear 0s infinite alternate' };
  @media(max-width: 1024px){
    height: auto;
    background-image: url(${StartBurstMobile1});
    background-position: center;
    animation: ${props => props.active ? 'none':'fade3 4s linear 0s infinite alternate' };
  }
`
const AnimatedStar2 = styled(AnimatedStar)`
  background-image: url(${StartBurst3});
  opacity: 0;
  animation: ${props => props.active ? 'none':'fade2 4s linear 4s infinite alternate' };
  @media(max-width: 1024px){
    background-position: center;
    background-image: url(${StartBurstMobile3});
    animation: ${props => props.active ? 'none':'fade3 4s linear 4s infinite alternate' };
  }
`
const AnimatedStar3 = styled(AnimatedStar)`
  background-image: url(${StartBurst2});
  opacity: 0;
  animation: ${props => props.active ? 'none':'fade2 4s linear 8s infinite alternate' };
  @media(max-width: 1024px){
    background-position: center;
    background-image: url(${StartBurstMobile2});
    animation: ${props => props.active ? 'none':'fade3 4s linear 8s infinite alternate' };
  }
`

const ThanksContainer = styled.div`
  width: 80%;
  max-width: 1100px;
  margin: 30vh auto;
  display:flex;
  flex-wrap: wrap;
  align-items:center;
  position:relative;
  z-index: 3;
  img {
    width: 55%;
    height: auto;
  }
  p {
    width: 45%;
    color: white;
    line-height: 21pt;
    border-left: 1px solid #E71F2B;
    padding-left: 30px;
  }
  @media(max-width: 1024px){
    width: 100%;
    flex-direction: column;
    align-items:flex-start;
    margin: 200px auto 100px;
    img {
      margin: 0 0 0 15px;
      width: 100%;
    }
    p {
      width: 100%;
      border-left: none;
      padding: 0 75px;
    }
  }
  @media(max-width: 800px){
    img {
      margin: 0;
    }
    p {
      padding: 0 30px;
    }
  }

`

const WhiteShape = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
`

const BlueShape = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  pointer-events:none;
`

const WhiteWrapper = styled.div`
  width: 100%;
  position:relative;
  background: white;
`
const BlueWrapper = styled.div`
  width: 100%;
  position:relative;
  background: #142441;
`


const BlueContent = styled.div`
  width: 80%;
  max-width: 1100px;
  margin: 0px auto;
  position:relative;
  z-index: 4;
  display:flex;
  align-items:center;
  padding-bottom: 15%;
  h3 {
    font-size: 30pt;
    line-height: 37pt;
    color: white;
  }
  > div:nth-child(1) {
    width: 65%;
    margin-top: -15%;
  }
  > div:nth-child(2) {
    width: 35%;
    margin-top: -15%;
    margin-left: -50px;
  }
  img {
    margin: 0;
  }
  p {
    line-height: 20pt;
    color: white;
    margin-top: 25px;
  }
  @media(max-width: 1024px){
    flex-direction: column;
    width: 100%;
    > div:nth-child(1), > div:nth-child(2) {
      width: 100%;
      margin: 0;
      padding: 0 25px;
    }
    > div:nth-child(1) {
      order: 2;
      overflow:hidden;
    }
    p, h3  {
      padding: 0 50px !important;
    }
    button {
      margin-left: 50px;
    }
  }
  @media(max-width: 800px){
    > div:nth-child(1) {
      img {
        width: 550px !important;
        max-width:none;
        margin-left: -50px;
      }
    }
    p, h3  {
      padding: 0 0px !important;
    }
    button {
      margin-left: 0px;
    }
  }
`


const WhiteContent = styled.div`
  width: 80%;
  max-width: 1100px;
  margin: 0px auto;
  position:relative;
  z-index: 4;
  display:flex;
  padding-bottom: 15%;
  h3 {
    font-size: 30pt;
    line-height: 37pt;
    color: #142441;
  }
  > div:first-child {
    width: 40%;
  }
  img {
    margin: -145px 0 45px;
  }
  p {
    line-height: 20pt;
    color: #757D82;
    margin-top: 25px;
  }
  @media(max-width: 1600px){
    padding-bottom: 12.5%;
  }
  @media(max-width: 1400px){
    padding-bottom: 10%;
  }
  @media(max-width: 1300px){
    padding-bottom: 12%;
    img {
      margin: -30px 0 0;
    }
  }
  @media(max-width: 1024px){
    flex-direction: column;
    width: 100%;
    > div:nth-child(1), > div:nth-child(2) {
      width: 100%;
      margin: 0;
      padding: 0 25px;
      overflow: hidden;
    }
    p, h3 {
      padding: 0 50px;
    }
    button {
      margin-left: 50px;
    }
    img {
      margin: 0px 0 -50px;
      width: 500px;
      max-width: none;
    }
  }
  @media(max-width: 800px){
    p, h3 {
      padding: 0 0px;
    }
    button {
      margin-left: 0px;
    }
  }
`

const StyledButton = styled.a`
    display:inline-block;
    background-color: #E71F2B;
    padding: 15px 50px;
    width: auto;
    text-align: center;
    border: 0;
    font-family: 'MuseoSans-500';
    margin: 25px 0 0 0;
    font-size: 12pt;
    cursor:pointer;
    outline: 0;
    border: 2px solid #E71F2B;
    transition: 0.2s;
    color: white;
    :hover {
      border: 2px solid white;
    }
    @media(max-width: 961px){
        max-width: 100%;
        margin: 25px 0% 45px;
    }
    span {
        color:  ${props => props.outline ? '#039365':'white !important'};
        font-size: 16pt !important;
    }
`

const ContestHomeBanner = ({locale, lockMode}) => {
    const { state, updateLocale } = useContext(EntryContext)
    return (
      <>
        <StarBackground>
          <ThanksContainer>
            <img src={Thanks} alt="Thanks" />
            <div className="smallline"></div>
            { !state.total_contest_entries ?
              <p>You can enter once a day until the contest closes, so be sure to come back tomorrow. In the meantime, check out the coupon below to save on Scotties<sup>®</sup> triple soft<sup>™</sup> with 3 layers for Ultimate Protection, or visit the official New England Patriots ProShop to see what you could buy with a $300 Patriots ProShop Gift Card.</p>
              :
              <p>You can enter once a day until the contest closes, so be sure to come back tomorrow. In the meantime, check out the new Scotties<sup>®</sup> triple soft<sup>™</sup> with 3 layers of softness for Ultimate Protection, or visit the official New England Patriots ProShop to see what you could buy with a $300 Patriots ProShop Gift Card.</p>
            }
            <img className="small-arrow" src={DownArrow1} alt="" />
        </ThanksContainer>
          <AnimatedStar
            active={state.modal} />
          <AnimatedStar2
            active={state.modal} />
          <AnimatedStar3
            active={state.modal} />
          <WhiteShape src={WhiteRound} />
        </StarBackground>
        <WhiteWrapper>
          <WhiteContent>


              { !state.total_contest_entries ?
                <div>
                  <h3>Save on Scotties<sup>®</sup> triple soft<sup>™</sup></h3>
                  <p>Get your coupon to save on Scotties<sup>®</sup> triple soft<sup>™</sup> with 3 Layers for Ultimate Protection, The Official Tissue of the New England Patriots. </p>
                  <StyledButton href="/patriotscoupon" target="_blank">Get Coupon</StyledButton>
                </div>
                :
                <div>
                  <h3>The Official Tissue of the New England Patriots</h3>
                  <p>Scotties® triple soft<sup>™</sup> is the official tissue of the New England Patriots because it's strong, with 3 layers of softness for Ultimate Protection.</p>
                  <StyledButton href="#" onClick={() => document.getElementById('shop-button').click()}>Buy Scotties</StyledButton>
                </div>

              }


            <div>
              <img src={Boxes} alt="" />
            </div>
          </WhiteContent>
          <BlueShape src={BlueRound} />
        </WhiteWrapper>
        <BlueWrapper>
          <BlueContent>
            <div>
              <img src={Proshop} alt="" />
            </div>
            <div>
              <h3>How Will You Spend Your Winnings?</h3>
              <p>Visit the official New England Patriots ProShop to find the very best selection of New England Patriots apparel and gear on the web!</p>
              <StyledButton href="https://proshop.patriots.com/" target="_blank">See Store</StyledButton>
            </div>
          </BlueContent>
        </BlueWrapper>
      </>

    )
}

export default ContestHomeBanner
