/* eslint-disable */
import React, {useContext} from "react"
import { Context as EntryContext } from '../context/EntryContext';
import styled from '@emotion/styled'
import Step1 from './steps/step1'
import Step2 from './steps/step2'
import Step3 from './steps/step3'
import Text from '../atoms/text'
// import { intlShape, injectIntl, FormattedMessage} from 'react-intl';

import Close from '../images/close2.svg'
import Back from '../images/back.svg'
import Head from '../images/patriots_head@2x.png'

const CloseButton = styled.img`
    position: absolute;
    right: 15px;
    top: 30px;
    z-index: 0;
    width: 15px;
    cursor:pointer;
`

const ProgressWrapper = styled.div`
    width: 100%;
    height: 7px;
    background-color: #e6e6e6;
    position: relative;
`

const ProgressBar = styled.div`
    background-image: linear-gradient(to right,#FF8888,#FF0000);
    width: ${props => props.percentComplete};
    height: 100%;
    display: block;
    transition: 0.6s;
`

const Steps = styled.div`
    display:flex;
    flex-direction: row;
    width: 50%;
    text-align:left;
    margin: 25px 25px 10px;
    p {
      margin: 0 0 0 10px;
    }
`

const BackButton = styled.img`
    width: 11px;
    height: 24px;
`

const Wrapper = styled.div`
    width: 100%;
    height: 10px;
`

const ModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  @media(max-width: 1024px){
    position:${props => props.visible ? 'relative':'absolute'};
    align-items: flex-start;
    height: auto;
  }
`

const ModalForm = styled.div`
  width: 80%;
  max-width: 1100px;
  margin: 50px auto 0;
  background: white;
  padding: 0;
  position: relative;
  z-index: 99;
  transition: 0.75s;
  transform: ${props => props.visible ? 'scale3d(1,1,1)':'scale3d(0.8,0.8,0.8)'};
  opacity: ${props => props.visible ? '1':'0'};
  pointer-events: ${props => props.visible ? 'all':'none'};
  @media(max-width: 1024px){
    position:${props => props.visible ? 'relative':'absolute'};
    width: 96%;
    max-width: 1100px;
    margin: 130px auto 0;
  }
`

const FlyingHead = styled.img`
  width: 200px;
  position:absolute;
  transition: 0.6s;
  top: -32px;
  left: ${props => props.percentComplete};
  @media(max-width: 1024px){
    display:none;
  }
`

const EntryForm = (props) => {
    const { state, closeModal, goBack } = useContext(EntryContext)

    return(
      <ModalWrapper
        visible={state.modal}>
        <ModalForm
          className="modal-form"
          visible={state.modal}>
          <ProgressWrapper className="progress-wrapper">
              <ProgressBar
                  percentComplete={state.modalStep === 1 ?
                      '20%': state.modalStep === 2 ? '50%':'80%'} />
              <FlyingHead src={Head}
                percentComplete={state.modalStep === 1 ?
                    '15%': state.modalStep === 2 ? '45%':'75%'} />
          </ProgressWrapper>
          <Steps>
              { state.modalStep > 1 ?
                  <BackButton
                      className="back-button"
                      onClick={() => goBack(state.modalStep)}
                      src={Back} />
                  :
                  ''
               }
               { !state.uuid ?
                  <Text
                      upperCase
                      margin="0 0 0 10px"
                      fontSize="12px"
                      fontColor="#595959">
                        { state.modalStep == 1 ?
                            <React.Fragment >
                              Step 1 of 3
                            </React.Fragment>
                            :null
                        }
                        { state.modalStep == 2 ?
                            <span className="clickable" onClick={() => goBack(state.modalStep)}>
                              Step 2 of 3
                            </span>
                            :null
                        }
                        { state.modalStep == 3 ?
                            <span className="clickable" onClick={() => goBack(state.modalStep)}>
                              Step 3 of 3
                            </span>
                            :null
                        }
                    </Text>
                  :
                    <Text
                        upperCase
                        margin="0 0 0 10px"
                        fontSize="12px"
                        fontColor="#B6B6B6">
                        { state.modalStep == 1 ?
                            <React.Fragment >
                              Step 1 of 2
                            </React.Fragment>
                            :
                            <React.Fragment >
                              Step 2 of 2
                            </React.Fragment>
                         }
                    </Text>
                }
          </Steps>
            { state.modalStep == 1 ?
                <Step1 />
                :
                null
            }
            { state.modalStep == 2 ?
                <Step2 />
                :
                null
            }
            { state.modalStep == 3 ?
                <Step3 />
                :
                null
            }
            <CloseButton
                src={Close}
                onClick={closeModal}
                />
        </ModalForm>
      </ModalWrapper>
    )
}

export default EntryForm
