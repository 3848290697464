/* eslint-disable */
import React, {useContext, useState} from "react"
import styled from '@emotion/styled'
import { Context as EntryContext } from '../context/EntryContext';
import Gear from '../images/settings.svg'
import CloseImg from '../images/close2.svg'
import Hawk from '../images/hawk.jpg'

const AdminToggle = styled.img`
  cursor:pointer;
  width: 50px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 999;
`

const AdminPanel = styled.div`
  width: 300px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: white;
  padding: 50px;
  z-index: 9999;
  font-family: Montserrat;
  border-left: 1px solid #c7bec7;
  transition: 0.3s;
  transform: ${props => props.visible ? 'translateX(0)':'translateX(100%)'};
  input {
    margin: 10px 0 20px;
  }
  label {
    color: black;
  }
  .logo {
    width: 150px;
    margin-top: 50px;
  }
`

const Close = styled.img`
  width: 15px;
  position: fixed;
  top: 25px;
  right: 25px;
  cursor: pointer;

`

const AdminControls = ({active}) => {

    const { state, controlContest } = useContext(EntryContext)
    const [admin,setAdmin] = useState(false)
    return (
      <>
        { active ?
            <>
              <AdminToggle
                onClick={() => setAdmin(true)}
                src={Gear} />
              <AdminPanel
                visible={admin}>
                <Close src={CloseImg}
                  onClick={() => setAdmin(false)} />
                <img className="logo" src={Hawk} alt="" />
                <br /><br /><br /><br />
                <h3>Contest Flow</h3>
                <br /><br />
                  {
                    !state.isContestComplete ?
                    <>
                      <label><input type="checkbox" name="modal" onClick={()=> controlContest(state,'modal') } /> &nbsp; Turn on modal</label>
                    </>
                    :null
                  }
                  { state.modal ?
                    <>
                      <label><input type="radio" name="step" value="1" onClick={()=> controlContest(state,'step1')} checked={state.modalStep == 1 ? 'checked':false} /> &nbsp; Step 1</label>
                      <label><input type="radio" name="step" value="2" onClick={()=> controlContest(state,'step2')} checked={state.modalStep == 2 ? 'checked':false} /> &nbsp; Step 2</label>
                      <label><input type="radio" name="step" value="3" onClick={()=> controlContest(state,'step3')} checked={state.modalStep == 3 ? 'checked':false} /> &nbsp; Step 3</label><br /><br />
                    </>
                    :null
                  }
                <label><input type="checkbox" name="isComplete" onClick={()=> controlContest(state,'complete') } /> &nbsp; Contest complete</label>


                <br />
                {
                  !state.isContestComplete ?
                  <>
                    <h3>reCaptcha</h3>
                    <label><input type="checkbox" name="modal" onClick={()=> controlContest(state,'captcha')} checked={!state.recaptcha ? 'checked':false} /> &nbsp; Activate reCaptcha</label><br />
                  </>
                  :null
                }

              </AdminPanel>
            </>

            :null
        }
      </>
    )
}

export default AdminControls
