/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import {Link} from 'gatsby'
import { Provider as EntryProvider } from './context/EntryContext';
import ContestHeader from "./components/ContestHeader.js"
// import { intlShape, injectIntl, FormattedMessage} from 'react-intl';
import Text from './atoms/text'
import WinImg from './images/win@2x.png'

import dayjs from 'dayjs'

import styled from '@emotion/styled'

const ContestContainer = styled.div`

    label,li, span {
        font-family: 'MuseoSans-300',sans-serif;
        color: white;
    }
    u {
        text-decoration: none;
    }
    p {
        > p {
            margin: 0 !important;
            padding: 0 !important;
        }
    }
`

const ContestFinished = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    text-align: center;
    justify-content: center;
    align-item:center;
    flex-direction: column;
    background-color: #202758;
    overflow: hidden !important;
`

const Logo = styled.img`
    width: 40%;
    max-width: 750px;
    margin: 0px auto;
`

const ContestBlock = ({children,startDate,endDate,locale, adminControls}) => {

    const start = dayjs(startDate)
    const end = dayjs(endDate)
    const today = dayjs()

    return (

        <EntryProvider>
            { today.isAfter(start) && today.isBefore(end) ?
                <ContestContainer>
                    {children}
                </ContestContainer>
                :
                <>
                <ContestHeader whiteVersion={true} />
                  <ContestFinished id="contest-finished">
                      <Logo src={WinImg} />
                      <Text
                          center
                          fontColor="white"
                          padding="0 15%">
                          The Scotties®  New England Patriots Sweepstakes has ended. Stay tuned for future contest and sweepstake emails. Plus, check out the website for your coupon to save on Scotties® triple soft™.
                      </Text>
                  </ContestFinished>
                </>

             }
        </EntryProvider>
    )
}

ContestBlock.propTypes = {
  children: PropTypes.node.isRequired,
}
export default ContestBlock
