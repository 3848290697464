/* eslint-disable */
import React from "react"
import styled from '@emotion/styled'
import PropTypes from "prop-types"
import { Check } from '@rocketseat/unform';

const StyledCheckbox = styled(Check)`

`

export default StyledCheckbox
