/* eslint-disable */
import React from "react"
import styled from '@emotion/styled'
import PropTypes from "prop-types"

const ColumnContainer = styled.div`
    position: relative;
    width: ${props => props.width ? props.width : '50%'};
    height: auto;
    margin: ${props => props.margin ? props.margin : '0'};
    padding ${props => props.padding ? props.padding : '0'};
    display: ${props => props.display ? props.display : 'flex'};
    flex-direction: ${props => props.direction ? props.direction : 'column'};
    flex-wrap: ${props => props.wrap ? props.wrap : 'wrap'};
    align-items:${props => props.centerMode ? 'center' : 'flex-start'};
    justify-content:${props => props.centerMode ? 'center' : 'flex-start'};
    flex-grow:1;
    @media(max-width: 1100px){
        padding: ${
            props => props.padding == '0 25% 0 15%' ||
            props.padding ==  '0 30% 0 20%' ||
            props.padding ==  '0 30%' ?
                '5%' : props.padding
            };
    }
    @media(max-width: 961px){
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        order: ${props => props.mobileOrder ? props.mobileOrder : 'inherit'};
    }
`

const Column = ({
    children,
    width,
    margin,
    padding,
    display,
    direction,
    wrap,
    centerMode,
    mobileOrder
  }) => (
      <ColumnContainer
          width={width}
          padding={padding}
          margin={margin}
          display={display}
          direction={direction}
          wrap={wrap}
          centerMode={centerMode}
          mobileOrder={mobileOrder}
          >
          {children}
      </ColumnContainer>
)

Column.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth:PropTypes.bool,
  padding:PropTypes.string,
  margin: PropTypes.string,
  display:PropTypes.string,
  direction:PropTypes.string,
  wrap:PropTypes.string,
  centerMode:PropTypes.bool
}

export default Column
