/* eslint-disable */
import React, {useContext, useState} from "react"
import styled from '@emotion/styled'
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { Context as EntryContext } from '../../context/EntryContext';
// import { intlShape, injectIntl, FormattedMessage} from 'react-intl';

import Text from '../../atoms/text'
import StyledInput from '../../components/fields/input'
import Error from '../../components/fields/error'
import Button from '../../components/fields/button'
import Section from '../../components/blocks/section'
import Column from '../../components/blocks/column'

const StyledEntryForm = styled(Form)`
    padding: 0 25px 50px;;
    label {
        color: #5F5F5F;
    }
    span {
        font-size: 11pt;
        line-height: 13pt;
        color: red;
        display: block;
        margin-left: 11px;
    }
    @media(max-width: 1024px){
        display: ${props => !props.modal ? 'none':'block'};
        position: relative;
        left: 0%;
        width: 94%;
        padding: 4%;
        span {
            font-size: 11pt;
            line-height: 17pt;
        }
    }
`

const FlexOrder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    span, label {
        order: 1;
        display: inline-block !important;
    }
    input {
        order: 2;
        width: 100%;
    }
`

const schema = Yup.object().shape({
    address: Yup.string()
        .required('required'),
    address2: Yup.string(),
    province: Yup.string(),
    city: Yup.string()
        .required('required')
});


const Step2 = (props) => {
    const { state, nextStep } = useContext(EntryContext)
    let initialData = {
        address: state.customer_address,
        address2:state.customer_address2,
        city:state.customer_city,
        province: state.customer_state
    };

    return(
      <>
          <StyledEntryForm
              schema={schema}
              onSubmit={(data) => nextStep(data,state.modalStep,state.uuid)}
              modal={state.modal}
              initialData={initialData}
              >
              <Section
                  fullWidth
                  padding="0"
                  margin="0">
                  <Column
                      width="calc(47% - 20px)"
                      padding="0px 10px"
                      mobileOrder="1">
                      <span id="left-text">
                        <Text
                          margin="0px 0 40px 0"
                          fontSize="20pt"
                          lineHeight="30pt"
                          padding="40px 15% 0 0">
                            We just need a few more details to enter you into the sweepstakes.
                        </Text>
                      </span>

                  </Column>
                  <Column
                      width="53%"
                      padding="0% 10px 0 0"
                       mobileOrder="2"
                      >
                      <Section
                          fullWidth
                          padding="0 10% 0 0"
                          >
                          <Column
                              width="100%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      label='Address'
                                      name="address"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="100%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      label="Address 2"
                                      name="address2"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="50%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      label="City"
                                      name="city"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="50%"
                              padding="5px 10px"
                              >
                              <StyledInput
                                  disabled
                                  label="State"
                                  name="province"
                                  />
                          </Column>
                          <Column
                              width="100%"
                              padding="5px 10px"
                              >
                              <div
                                  style={{
                                          width: '100%'
                                      }}>
                                      <Button
                                         type="submit">
                                         {!state.uuid ? 'Continue':'Finish'}
                                       </Button>
                              </div>
                          </Column>
                      </Section>
                  </Column>

              </Section>
          </StyledEntryForm>
      </>
    )
}

export default Step2
