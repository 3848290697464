/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import styled from '@emotion/styled'

const StyledError = styled.p`
    display: block;
    padding: 0;
    margin: 20px 0 0 0;
    font-size: 10pt;
    color: red;
    padding: 0;

`

const Error = ({children}) => (
    <StyledError>
        {children}
    </StyledError>
)

Error.propTypes = {
  text: PropTypes.string
}

export default Error
