export const selectState = (zipcode) => {

  zipcode = parseInt(zipcode);

  let st;
  let state;

  /* Code cases alphabetized by state */
  if (zipcode >= 6000 && zipcode <= 6999) {
      st = 'CT';
      state = 'Connecticut';
  } else if (zipcode >= 3900 && zipcode <= 4999) {
      st = 'ME';
      state = 'Maine';
  } else if (zipcode >= 1000 && zipcode <= 2799) {
      st = 'MA';
      state = 'Massachusetts';
  } else if (zipcode >= 3000 && zipcode <= 3899) {
      st = 'NH';
      state = 'New Hampshire';
  }  else if (zipcode >= 2800 && zipcode <= 2999) {
      st = 'RI';
      state = 'Rhode Island';
  }  else if (zipcode >= 5000 && zipcode <= 5999) {
      st = 'VT';
      state = 'Vermont';
  }  else {
      return false
  }
  return st;
}

export default selectState
