/* eslint-disable */
import React, {useContext} from "react"
import ContestHomeBanner from "./ContestHomeBanner.js"
import ContestThankYouBanner from "./ContestThankYouBanner.js"
import styled from '@emotion/styled'
import { Context as EntryContext } from '../context/EntryContext';
import Loading from '../images/loader.svg'

const Loader = styled.div`
  width: 50px;
  height: 50px;
  position: fixed;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  background-image: url(${Loading});
  transform: rotate(45deg);
  animation-name: spin;
  animation-duration: 2.5s;
  opacity: 0.8;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  z-index: 9999;
`

const ContestContent = (props) => {

    const { state } = useContext(EntryContext)

    return (
      <main style={{position:'relative'}}>
          <Loader style={{'display':state.isLoading ? 'block' : 'none'}}/>
          {!state.isContestComplete ?
              <ContestHomeBanner locale={props.locale} />
              :
              <ContestThankYouBanner
                  locale={props.locale}
                  blogPosts={[]} />
          }
      </main>
    )
}

export default ContestContent
