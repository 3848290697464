/* eslint-disable */
import React, {useContext} from "react"
import styled from '@emotion/styled'
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { Context as EntryContext } from '../../context/EntryContext';
import {selectState} from '../../helpers/States.js'
// import { intlShape, injectIntl, FormattedMessage} from 'react-intl';

import Text from '../../atoms/text'
import StyledInput from '../../components/fields/input'
import Checkbox from '../../components/fields/checkbox'
import Button from '../../components/fields/button'
import Section from '../../components/blocks/section'
import Column from '../../components/blocks/column'

import Icon1 from '../../images/icon1.svg'
import Icon2 from '../../images/icon2.svg'
import Icon3 from '../../images/icon3.svg'

const StyledEntryForm = styled(Form)`
  padding: 0 25px 50px;
    label {
        color: #5F5F5F;
    }
    span {
        font-size: 11pt;
        line-height: 13pt;
        color: red;
        display: block;
        margin-left: 11px;
    }
    @media(max-width: 1024px){
        display: ${props => !props.modal ? 'none':'block'};
        position: relative;
        left: 0%;
        width: 94%;
        padding: 4%;
        span {
            font-size: 11pt;
            line-height: 17pt;
        }
    }
`

const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
const postalCodeRegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

const FlexOrder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    span, label {
        order: 1;
        display: inline-block !important;
    }
    input {
        order: 2;
        width: 100%;
    }
`
const LegalTerms = styled.div`
    font-size: 11pt;
    color: #A2A2A2;
    font-family: 'MuseoSans-500';
    font-weight: 300;
    margin-top: 10px;
    label {
        padding-left: 10px;
    }
`

const ColorList = styled.ul`
    margin: 0 0 25px;
`
const Items = styled.li`
    color: #006737;
    list-style-type: none;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center left;
    margin: 10px 0;
    padding: 0 0 0 35px;
    font-size: 15pt;

`
const Item1 = styled(Items)`
    background-image: url(${Icon1});
`
const Item2 = styled(Items)`
    background-image: url(${Icon2});
`
const Item3 = styled(Items)`
    background-image: url(${Icon3});
`

const ResponsiveSpacer = styled.div`
    @media(max-width: 961px){
        display: block;
        width: 100%;
        height: 50px;
    }
`



const Step1 = (props) => {
    const { state, nextStep } = useContext(EntryContext)
    const schema = Yup.object().shape({
        firstname: Yup.string()
            .required('Required'),
        lastname: Yup.string()
            .required('Required'),
        postal_code: Yup.string()
            .test('len', 'Invalid Zip Code', val => selectState(val))
            .required('Required'),
        year_of_birth: Yup.number('Required')
            .min(1900,'Invalid')
            .max(2002,'Invalid')
            .typeError('Required'),
        phone: Yup.string()
            .matches(phoneRegExp, 'Invalid phone')
            .required('Required'),
        newsletter: state.uuid ? Yup.bool() : Yup.bool()
          .oneOf([true], 'newsletter error')
    });

    let initialData = {
        newsletter: state.customer_newsletter,
        firstname: state.customer_firstname,
        lastname:state.customer_lastname,
        postal_code:state.customer_zipcode,
        year_of_birth: state.customer_yearofbirth,
        phone: state.customer_phone
    };

    return(
      <>
          <StyledEntryForm
              schema={schema}
              onSubmit={(data) => {nextStep(data,state.modalStep,state.uuid)}}
              modal={state.modal}
              initialData={initialData}
              >
              <Section
                  fullWidth
                  padding="0"
                  margin="0">
                  <Column
                      width="calc(47% - 20px)"
                      padding="0px 10px 0"
                      mobileOrder="1"
                      >

                      { !state.uuid ?

                          <span id="left-text">
                            <Text
                              margin="0px 0 40px 0"
                              fontSize="20pt"
                              lineHeight="30pt"
                              padding="40px 15% 0 0">
                                You only need to do this once. Next time, all you’ll need to enter is your email address.
                            </Text>
                          </span>
                        :
                            <span id="left-text">
                              <Text
                                margin="0px 0 40px 0"
                                fontSize="20pt"
                                lineHeight="30pt"
                                padding="40px 15% 0 0">
                                  You only need to do this once. Next time, all you’ll need to enter is your email address.
                              </Text>
                            </span>

                      }
                  </Column>
                  <Column
                      width="53%"
                      padding="0% 10px 0 0"
                      mobileOrder="2"
                      >
                      <Section
                          fullWidth
                          padding="0 0 0 0"
                          >
                          <Column
                              width="50%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      label='First Name'
                                      name="firstname"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="50%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      label='Last Name'
                                      name="lastname"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="50%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      uppercase
                                      label='Zip code'
                                      name="postal_code"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="50%"
                              padding="5px 10px"
                              >
                              <FlexOrder>
                                  <StyledInput
                                      label='Year of birth'
                                      name="year_of_birth"
                                      maxLength="4"
                                      />
                              </FlexOrder>
                          </Column>
                          <Column
                              width="100%"
                              padding="5px 10px">
                              <FlexOrder>
                                  <StyledInput
                                      mask=""
                                      label="Telephone"
                                      name="phone"
                                      />
                              </FlexOrder>
                              <Button type="submit">Continue</Button>
                          </Column>

                      </Section>
                  </Column>

              </Section>
          </StyledEntryForm>
      </>
    )
}

export default Step1
