/* eslint-disable */
import React from "react"
import styled from '@emotion/styled'

const StyledText = styled.p`
    display: block;
    width: 100%;
    position:relative;
    font-family: ${props => props.fontFamily ? props.fontFamily + ', arial !important' : 'MuseoSans-300, arial !important'};
    font-weight: ${props => props.bold ? 'bold' : '300'};
    font-size: ${props => props.fontSize ? props.fontSize : '14pt'};
    line-height: ${props => props.lineHeight ? props.lineHeight : '18pt'};
    text-transform: ${props => props.upperCase ? 'uppercase' : 'inherit'};
    color: ${props => props.fontColor ? props.fontColor : '#383838'};
    margin: ${props => props.margin ? props.margin : '0 0 40px 0'};
    padding: ${props => props.padding ? props.padding : '0'};
    text-align: ${props => props.center ? 'center' : 'left'};
    span {
        margin: 0 !important;
        padding: 0 !important;
        font-family: ${props => props.fontFamily ? props.fontFamily + ' !important' : 'MuseoSans-300 !important'}, arial;
        font-weight: ${props => props.fontFamily ? 'normal !important' : '300 !important'};
        font-size: ${props => props.fontSize ? props.fontSize + ' !important' : '14pt !important'};
        color: ${props => props.fontColor ? props.fontColor + ' !important' : '#383838 !important'};
        color: ${props => props.fontColor ? props.fontColor + ' !important' : '#383838 !important'};
        line-height: ${props => props.lineHeight ? props.lineHeight + ' !important' : '18pt !importan'};
    }
    @media(max-width: 1100px){
        font-size: ${props => props.fontSize == '50pt' ? '35pt' : props.fontSize};
        margin: ${props => props.fontSize == '50pt' ? '0' : '0 0 40px 0'};
        line-height: ${props => props.lineHeight == '60pt' ? '45pt' : props.lineHeight};
        padding: ${
            props => props.padding == '0 25% 0 15%' ||
            props.padding ==  '0 30% 0 20%' ||
            props.padding ==  '0 30%' ?
                '5%' : props.padding
            };
    }
    @media(max-width: 961px){
        font-size: ${props => props.fontSize == '50pt' ? '35pt' : props.fontSize};
        line-height: ${props => props.lineHeight == '60pt' ? '45pt' : props.lineHeight};
        padding: ${
            props => props.padding == '0 25% 0 15%' ||
            props.padding ==  '0 30% 0 20%' ||
            props.padding ==  '0 30%' ?
                '5%' : props.padding
            };
    }
`

const Text = ({
    children,
    fontSize,
    lineHeight,
    fontColor,
    fontFamily,
    upperCase,
    margin,
    padding,
    center,
    bold
    }) => (
  <StyledText
      fontSize={fontSize}
      fontColor={fontColor}
      fontFamily={fontFamily}
      lineHeight={lineHeight}
      upperCase={upperCase}
      margin={margin}
      padding={padding}
      center={center}
      bold={bold}
      >
      {children}
  </StyledText>
)

export default Text
