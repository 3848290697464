/* eslint-disable */
import React, {useContext} from "react"
import styled from '@emotion/styled'
import { Context as EntryContext } from '../context/EntryContext';
import Header from "../../components/header.js";

const ContestHeader = ({whiteVersion}) => {
    const { state } = useContext(EntryContext)
    return(
            <div id="patriots-header">
                <Header
                  retailers={[]}
                  whiteVersion={whiteVersion}
                  addOnText={'TRIPLE SOFT<sup>™</sup> THE OFFICIAL TISSUE OF THE NEW ENGLAND PATRIOTS'}
                  hideBuy={!state.isContestComplete ? true : false}
                  hideMenu={!state.isContestComplete ? true : false}>
                </Header>
            </div>
    )
}

export default ContestHeader
