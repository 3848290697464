/* eslint-disable */
import React, { useContext, useEffect } from "react"
import styled from '@emotion/styled'
import { Context as EntryContext } from '../context/EntryContext';
import { Link } from 'gatsby'

import HomeForm from '../forms/homeForm'
import EntryForm from '../forms/entryForm'

import Back from '../images/star_background@2x.jpg'
import RoundShape1 from '../images/right_shape@2x.png'
import RoundShape2 from '../images/left_shape@2x.png'

import RoundShapeMobile1 from '../images/right_shape_mobile@2x.png'
import RoundShapeMobile2 from '../images/left_shape_mobile@2x.png'

import StartBurst1 from '../images/StartBurstx@2x.png'
import StartBurst2 from '../images/StartBurstx2@2x.png'
import StartBurst3 from '../images/StartBurstx3@2x.png'

import StartBurstMobile1 from '../images/StartBurst_mobile@2x.png'
import StartBurstMobile2 from '../images/StartBurst2_mobile@2x.png'
import StartBurstMobile3 from '../images/StartBurst3_mobile@2x.png'

import TissueBox from '../images/tissueRight@2x.png'

import '../css/patriots2020.css'

const StarBackground = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${Back});
  background-size: cover;
  position: relative;
  z-index: 0;
  overflow: hidden !important;
  @media(max-width: 1024px){
    height: auto;
    padding-bottom: ${props => props.active ? '50px':'390px' };

  }
  @media(max-width: 600px){
    height: auto;
    padding-bottom: ${props => props.active ? '50px':'290px' };
  }
`

const WhiteGradient = styled.div`
  width: 100%;
  height: 40vh;
  position: absolute;
  bottom: 0;
  display: block;
  opacity: 1;
  background-image: linear-gradient(transparent 0%, transparent 50%, white);
  z-index:2;
  margin-bottom: -1px;
`

const RightShape = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  bottom: 0;
  display: block;
  background-image: url(${RoundShape1});
  background-size: 100% 100vh;
  background-position: top right;
  background-repeat: no-repeat;
  z-index:1;
  pointer-events: none;
  transition: 0.5s;
  transform: ${props => props.active ? 'translateX(100%)':'translateX(0)' };
  @media(max-width: 1024px){
    background-size: 100% auto;
    height: 375px;
    background-position: bottom right;
  }
  @media(max-width: 600px){
    background-image: url(${RoundShapeMobile1});
  }
`
const LeftShape = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  bottom: 0;
  display: block;
  background-image: url(${RoundShape2});
  background-size: cover;
  background-position: bottom left;
  z-index:1;
  pointer-events: none;
  transition: 0.5s;
  background-repeat: no-repeat;
  transform: ${props => props.active ? 'translateX(-100%)':'translateX(0)' };
  @media(max-width: 1024px){
    background-image: url(${RoundShape2});
    background-size: 100% auto;
    height: 375px;
    background-position: bottom right;
  }
  @media(max-width: 600px){
    background-image: url(${RoundShapeMobile2});
  }
`

const AnimatedStar = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background-image: url(${StartBurst1});
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  z-index:0;
  opacity: 0;
  transform: rotate(2deg);
  pointer-events: none;
  transition: 0.5s;
  opacity: 0;
  animation: ${props => props.active ? 'none':'fade1 4s linear 0s infinite alternate' };
  @media(max-width: 1024px){
    height: auto;
    background-image: url(${StartBurstMobile1});
    background-position: center;
    animation: ${props => props.active ? 'none':'fade3 4s linear 0s infinite alternate' };
  }
`
const AnimatedStar2 = styled(AnimatedStar)`
  background-image: url(${StartBurst3});
  opacity: 0;
  animation: ${props => props.active ? 'none':'fade1 4s linear 4s infinite alternate' };
  @media(max-width: 1024px){
    background-position: center;
    background-image: url(${StartBurstMobile3});
    animation: ${props => props.active ? 'none':'fade3 4s linear 4s infinite alternate' };
  }
`
const AnimatedStar3 = styled(AnimatedStar)`
  background-image: url(${StartBurst2});
  opacity: 0;
  animation: ${props => props.active ? 'none':'fade1 4s linear 8s infinite alternate' };
  @media(max-width: 1024px){
    background-position: center;
    background-image: url(${StartBurstMobile2});
    animation: ${props => props.active ? 'none':'fade3 4s linear 8s infinite alternate' };
  }
`

const TissueImage = styled.img`
  width: 60%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 4;
  pointer-events: none;
  transition: 0.5s;
  transform: ${props => props.active ? 'translateX(100%)':'translateX(0)' };
  @media(max-width: 1024px){
    width: 650px;
    max-width: none;
    right: -130px;
  }
  @media(max-width: 600px){
    width: 450px;
    max-width: none;
    right: -130px;
  }
`

const ContestHomeBanner = ({locale, lockMode}) => {
    const { state, updateLocale } = useContext(EntryContext)
    return (
      <StarBackground
        active={state.modal}>
        <HomeForm />
        <EntryForm />
        <LeftShape
          active={state.modal} />
        <TissueImage
          src={TissueBox}
          active={state.modal} />
        <RightShape
          active={state.modal} />
        <WhiteGradient />
        <AnimatedStar
          active={state.modal} />
        <AnimatedStar2
          active={state.modal} />
        <AnimatedStar3
          active={state.modal} />
      </StarBackground>
    )
}

export default ContestHomeBanner
