/* eslint-disable */
import React from "react"
import Layout from '../components/layout.js'
import SEO from "../components/seo.js";

import ContestBlock from "../contest/ContestBlock.js"
import ContestHeader from "../contest/components/ContestHeader.js"
import ContestContent from "../contest/components/ContestContent.js"
import ContestFooter from "../contest/components/ContestFooter.js"
import AdminControls from "../contest/components/AdminControls.js"

const locale = 'en'

const IndexPage = ({data}) => {

  const metaData = 'a:2:{s:5:"title";s:39:"Win with the Patriots | Scotties Facial";s:11:"description";s:301:"There’s a box of Scotties to fit every room in your home. Whether you like to keep it simple with 2-ply, prefer the added protection of a 3-ply, or want the soothing touch of aloe, Scotties is always comfort inspired. Get more Scotties. Share style inspiration, sign up for news rewards and savings.";}'

  return (
    <Layout>
      <SEO meta={metaData} />
      {/* Date format YYYY-MM-DD */}
      {/*
        * To implement the contest, just copy & paste the <ContestBlock> component
        * on the desired page and copy the "contest" folder in the /src directory
        * the styles can be edited with props or inside individual components
        */}
        {/* Set adminControls to "true" if you want to enable the admin toolbar
          * to manually control the contest flow and the captcha
          * NEVER leaver true in production
          */}
          <main>
            <h1 className="sr-only">Scotties Facial Patriots Sweeps</h1>
            <ContestBlock
                startDate="2020-09-14"
                endDate="2020-10-06"
                locale='en'
                >
                <ContestHeader whiteVersion={true} />
                <ContestContent
                    locale={locale} />
                  <ContestFooter locale={locale}  />
                <AdminControls active={false} />
            </ContestBlock>
          </main>
    </Layout>
  )
}

export default IndexPage
