/* eslint-disable */
import React from "react"
import styled from '@emotion/styled'
import Section from './blocks/section'
import Column from './blocks/column'
import Text from '../atoms/text'
// import { intlShape, injectIntl, FormattedMessage} from 'react-intl';

import Facebook from "../images/facebook.svg";
import Instagram from "../images/instagram.svg";

const LegalText = styled.small`
    border-top: 2px solid #AEAEAE;
    padding: 30px 0;
    margin-top: 25px;
    color: #a8a8a8;
    span {
        font-family: 'MuseoSans-300' !important;
        font-size: 9pt;
        line-height: 14pt;
        color: #AEAEAE;
        a{
            font-family: 'MuseoSans-300';
            color: #50586E;
        }

    }
    a{
        color: #787878;
        text-decoration: underline;
    }
    @media(max-width: 961px){
        padding: 25px 2%;
        margin: 0 2% 25px;
    }

`

const Social = styled.img`
    width: 20px;
    display: inline-block;
    margin: 10px;
`

const FooterLink = styled.a`
    font-family:MuseoSans-300 !important;
    color:#50586E;
    text-decoration:none;
    font-size:11pt;
    display: block;
    margin: 10px 15px 0 0;
`

const SocialContainer = styled.div`
    @media(max-width: 961px){
        display: flex;
        flex-direction: row;
        margin-top: 25px;
        margin-left: 2%;
    }
`

const ResponsiveContaier = styled.div`
    display: flex;
    flex-direction: row;
    @media(max-width: 961px){
        display: block;
        margin-left: 4%;
        margin-bottom: 45px;
    }
`

const ResponsiveContaier2 = styled.div`
    display: block;
    width: 100%;
    text-align: right;
    a, p {
        text-align: right;
    }
    @media(max-width: 961px){
        margin-left: 4%;
        width: 96%;
        margin-bottom: 45px;
        text-align: left;
        a, p {
            text-align: left;
            margin 0 !important;
        }
    }
`

const ContestFooter = ({locale,landing,extraLines}) => (
  <footer>
      <Section
        margin="0 auto">
          <Column
              width="65%"
              direction="row">
              <SocialContainer>
                  <a href="https://www.facebook.com/ScottiesTissues" target="_blank"><Social src={Facebook} /></a>
                  <a href="https://www.instagram.com/scottiestissues/" target="_blank"><Social src={Instagram} /></a>
              </SocialContainer>

              <Column
                  width="100%"
                  direction="row"
                  margin="20px 0 0 0"
                  >
                  <ResponsiveContaier>
                      <FooterLink href='/patriots_2020.pdf' target="_blank">Official Rules</FooterLink>
                      <FooterLink href="https://www.scottiesfacial.com/privacy-policy/" target="_blank">Privacy Policy</FooterLink>
                      <FooterLink href="https://www.scottiesfacial.com/user-agreement/" target="_blank">User agreement</FooterLink>
                  </ResponsiveContaier>

              </Column>
          </Column>
          <Column
              width="35%">
              <ResponsiveContaier2>
                  <Text
                      upperCase
                      fontFamily="MuseoSans-500"
                      fontColor="#50586E"
                      fontSize="16px"
                      margin="0">Questions? Contact Us:</Text>
                      <a
                        style={{
                            'fontFamily':'MuseoSans-300',
                            'color':'#ACACAC',
                            'textDecoration':'none',
                            'fontSize':'14pt'
                        }}
                        href="mailto:letushelp@scottiespromotions.com ">
                        letushelp@scottiespromotions.com
                      </a>
              </ResponsiveContaier2>
          </Column>
          <Column
              width="70%">
              <LegalText>
                  *NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. Begins 9/14/20 at 9:00:00 a.m. ET and ends 10/5/20 at 11:59:59 p.m. ET. Open to legal residents of Massachusetts, Rhode Island, New Hampshire, Maine, Vermont, and Connecticut, 18 years of age or older at time of entry. Odds of winning depend on the number of eligible entries received. Void outside of MA, RI, NH, ME, VT, and CT and where prohibited.  Go to <a href="https://www.scottiesfacial.com/patriotssweeps">www.scottiesfacial.com/patriotssweeps</a> for Official Rules, which govern, prize details, and how to enter. Sponsor: Irving Consumer Products, Inc., 25 Burlington Mall Rd. 6th Floor Burlington, MA 01803.
                  <br/><br/>
                  <sup>©</sup>2020 Irving Consumer Products, Inc. Scotties<sup>®</sup>  is a registered trademark of Kimberly Clark Worldwide, Inc. used under license by Irving Consumer Products, Inc. TM trademarks of Irving Consumer Products Limited. Used under license.
                  <br /><br />
                  Sustainable Forestry Initiative<sup>®</sup>  and SFI<sup>®</sup> are Registered Trademarks of SFI Inc. used under license by Irving Consumer Products, Inc.
              </LegalText>
          </Column>
      </Section>
  </footer>
)

export default ContestFooter
